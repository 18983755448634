<template>
  <div class="edu-platfrom-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-12 col-sm-12">
          <div class="edu-platfrom-content">
            <p class="sub-title">Best Online Learning Platform</p>
            <h2>
              One Platfrom & Many <span class="shape02">Courses</span> For You
            </h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered.
            </p>
            <div class="platfrom-list">
              <div class="items">
                <div class="edu-platfrom-img">
                  <img src="../../assets/img/svg/ok.svg" alt="banner-img" />
                </div>
                <div class="platfrom-single-content">
                  <h3>9/10 Average Satisfaction Rate</h3>
                </div>
              </div>
              <div class="items">
                <div class="edu-platfrom-img">
                  <img src="../../assets/img/svg/ok.svg" alt="banner-img" />
                </div>
                <div class="platfrom-single-content">
                  <h3>96% Completitation Rate</h3>
                </div>
              </div>
              <div class="items">
                <div class="edu-platfrom-img">
                  <img src="../../assets/img/svg/ok.svg" alt="banner-img" />
                </div>
                <div class="platfrom-single-content">
                  <h3>Friendly Environment & Expert Teacher</h3>
                </div>
              </div>
            </div>
            <a href="#" class="default-btn">Explore Our Courses</a>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
          <div class="edu-platfrom-image">
            <img src="../../assets/img/all-img/about2.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BestOnlineLearningPlatform",
};
</script>