<template>
  <div class="edu-about-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12">
          <div class="edu-about-image">
            <img src="../../assets/img/all-img/about3.png" alt="image" />
          </div>
        </div>
        <div class="col-lg-5 col-md-12">
          <div class="edu-about-content">
            <p class="sub-title">About Edusmart</p>
            <h2>
              We Provide The Best Online <span class="shape02">Education</span>
            </h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour.
            </p>
            <div class="progress-content">
              <div class="progress-section">
                <div class="progress-title">
                  <h4>Business Studies</h4>
                  <span class="title">86%</span>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar psc01"
                    role="progressbar"
                    aria-valuenow="86"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="max-width: 86%"
                  ></div>
                </div>
              </div>
              <div class="progress-section">
                <div class="progress-title">
                  <h4>Marketing</h4>
                  <span class="title">67%</span>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar psc02"
                    role="progressbar"
                    aria-valuenow="67"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="max-width: 67%"
                  ></div>
                </div>
              </div>
              <div class="progress-section mb-0">
                <div class="progress-title">
                  <h4>Design & Development</h4>
                  <span class="title">95%</span>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar psc03"
                    role="progressbar"
                    aria-valuenow="95"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="max-width: 95%"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>