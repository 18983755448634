<template>
  <div class="brands-area-area bbColor pt-100 pb-70">
    <div class="container">
      <ul class="brands-list">
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/b1.svg" alt="brands" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/b2.svg" alt="brands" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/b3.svg" alt="brands" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/b4.svg" alt="brands" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/b5.svg" alt="brands" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandsContent",
};
</script>