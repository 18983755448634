<template>
  <div class="edu-courses-area banner07 ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Featured Courses</p>
        <h2>Choose Unlimited <span class="shape02">Courses</span></h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <router-link to="/courses-details" class="single-courses-link">
            <div class="single-courses-box02">
              <div class="image">
                <img src="../../assets/img/all-img/c1.png" alt="image" />
              </div>
              <div class="content">
                <div class="content-herd">
                  <span class="cr-price">$29.07</span>
                  <ul class="ratings">
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                  </ul>
                </div>

                <h3>Basic Fundamentals of Interior & Graphics Design</h3>
                <ul class="cr-items">
                  <li>
                    <i class="bx bx-food-menu"></i> <span>2 Lessons</span>
                  </li>
                  <li><i class="bx bx-user"></i> <span>4k Students</span></li>
                </ul>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <router-link to="/courses-details" class="single-courses-link">
            <div class="single-courses-box02">
              <div class="image">
                <img src="../../assets/img/all-img/c2.png" alt="image" />
              </div>
              <div class="content">
                <div class="content-herd">
                  <span class="cr-price">Free</span>
                  <ul class="ratings">
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                  </ul>
                </div>
                <h3>Increasing Engagement with Instagram & Facebook</h3>
                <ul class="cr-items">
                  <li>
                    <i class="bx bx-food-menu"></i> <span>2 Lessons</span>
                  </li>
                  <li><i class="bx bx-user"></i> <span>4k Students</span></li>
                </ul>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <router-link to="/courses-details" class="single-courses-link">
            <div class="single-courses-box02">
              <div class="image">
                <img src="../../assets/img/all-img/c3.png" alt="image" />
              </div>
              <div class="content">
                <div class="content-herd">
                  <span class="cr-price">$22</span>
                  <ul class="ratings">
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                  </ul>
                </div>
                <h3>Introduction to Color Theory & Basic UI/UX</h3>
                <ul class="cr-items">
                  <li>
                    <i class="bx bx-food-menu"></i> <span>2 Lessons</span>
                  </li>
                  <li><i class="bx bx-user"></i> <span>4k Students</span></li>
                </ul>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <router-link to="/courses-details" class="single-courses-link">
            <div class="single-courses-box02">
              <div class="image">
                <img src="../../assets/img/all-img/c4.png" alt="image" />
              </div>
              <div class="content">
                <div class="content-herd">
                  <span class="cr-price">$58.55</span>
                  <ul class="ratings">
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                  </ul>
                </div>
                <h3>Financial Security Thinking and Principles Theory</h3>
                <ul class="cr-items">
                  <li>
                    <i class="bx bx-food-menu"></i> <span>2 Lessons</span>
                  </li>
                  <li><i class="bx bx-user"></i> <span>4k Students</span></li>
                </ul>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <router-link to="/courses-details" class="single-courses-link">
            <div class="single-courses-box02">
              <div class="image">
                <img src="../../assets/img/all-img/c5.png" alt="image" />
              </div>
              <div class="content">
                <div class="content-herd">
                  <span class="cr-price">$36.55</span>
                  <ul class="ratings">
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                  </ul>
                </div>
                <h3>Logo Design: From Concept to Presentation</h3>
                <ul class="cr-items">
                  <li>
                    <i class="bx bx-food-menu"></i> <span>2 Lessons</span>
                  </li>
                  <li><i class="bx bx-user"></i> <span>4k Students</span></li>
                </ul>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <router-link to="/courses-details" class="single-courses-link">
            <div class="single-courses-box02">
              <div class="image">
                <img src="../../assets/img/all-img/c6.png" alt="image" />
              </div>
              <div class="content">
                <div class="content-herd">
                  <span class="cr-price">$52.02</span>
                  <ul class="ratings">
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                    <li><i class="bx bxs-star"></i></li>
                  </ul>
                </div>
                <h3>Professional Ceramic Moulding for Beginners</h3>
                <ul class="cr-items">
                  <li>
                    <i class="bx bx-food-menu"></i> <span>2 Lessons</span>
                  </li>
                  <li><i class="bx bx-user"></i> <span>4k Students</span></li>
                </ul>
              </div>
            </div>
          </router-link>
        </div>
        <div class="section-button">
          <router-link to="/courses" class="default-btn"
            >View All Courses</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeaturedCourses",
};
</script>