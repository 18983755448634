<template>
  <div class="edu-events-area ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Join With Us</p>
        <h2>Upcoming <span class="shape02">Events</span></h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e1.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-datels">
                  International Art Fair 2023
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2023 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <router-link to="/contact" class="default-btn primery-black">
                Book A Seat
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e2.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-datels">
                  International Art Fair 2023
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2023 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <router-link to="/contact" class="default-btn primery-black">
                Book A Seat
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e3.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-datels">
                  International Art Fair 2023
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2023 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <router-link to="/contact" class="default-btn primery-black">
                Book A Seat
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpcomingEvents",
};
</script>