<template>
  <div class="edu-events-area ptb-100">
    <div class="container">
      <div class="edu-grid-sorting row align-items-center">
        <div class="col-lg-6 col-md-6 result-count">
          <div class="result-show-text">
            <p>Showing 12 Courses of 52</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 ordering">
          <div class="select-box">
            <label></label>
            <select>
              <option>Sort By: Popularity</option>
              <option>Popularity</option>
              <option>Another option</option>
              <option>Potato</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e1.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  International Art Fair 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e2.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  International Art Fair 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e3.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  International Art Fair 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e4.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  Street Performance 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i
                  ><span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e5.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  Street Performance 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e6.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  Street Performance 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e7.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  Street Performance 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e8.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  Street Performance 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i
                  ><span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-event-box">
            <div class="image">
              <img src="../../assets/img/all-img/e9.png" alt="image" />
            </div>
            <div class="content">
              <h3>
                <router-link to="/event-single">
                  Street Performance 2024
                </router-link>
              </h3>
              <ul class="ev-items">
                <li>
                  <i class="bx bx-calendar"></i>
                  <span>Thu, Oct 5, 2024 03:48 PM</span>
                </li>
                <li>
                  <i class="bx bx-map"></i><span>Humberg City, Germany</span>
                </li>
              </ul>
              <a href="#" class="default-btn primery-black">Book A Seat</a>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="pagination-area text-center">
            <a href="#" class="prev page-numbers">
              <i class="bx bx-chevrons-left"></i>
            </a>
            <span class="page-numbers current" aria-current="page">1</span>
            <a href="#" class="page-numbers">2</a>
            <a href="#" class="page-numbers">3</a>
            <a href="#" class="page-numbers">4</a>
            <a href="#" class="next page-numbers">
              <i class="bx bx-chevrons-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventsContent",
};
</script>