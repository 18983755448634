<template>
  <div :class="['edu-navbar-area navbar-area', { 'is-sticky': isSticky }]">
    <!-- <div class="edumim-responsive-nav">
      <div class="container">
        <div class="edumim-responsive-menu">
          <div class="logo">
            <router-link to="/">
              <img src="../../assets/img/logo/logo.svg" alt="logo" />
            </router-link>
          </div>
        </div>
      </div>
    </div> -->
    <div class="edumim-nav">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link class="navbar-brand" to="/">
            <img src="../../assets/img/logo/logo.svg" alt="logo" />
          </router-link>

          <div
            class="navbar-toggler"
            @click="active = !active"
            :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ active: button_active_state }"
            v-on:click="button_active_state = !button_active_state"
          >
            <i class="bx bx-menu"></i>
            <i class="bx bx-x"></i>
          </div>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="/" class="nav-link">
                  Beranda
                </router-link>
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                  Profil
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/pengantar" class="nav-link">
                      Kata Pengantar
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/sejarah" class="nav-link">
                      Sejarah Singkat
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/visimisi" class="nav-link">
                      Visi dan Misi
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/struktur" class="nav-link">
                      Struktur Organisasi
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/kepsek" class="nav-link">
                      Kepala Sekolah
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/komite" class="nav-link">
                      Komite Sekolah
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/gtk" class="nav-link">
                      Guru & Staff
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/sarpras" class="nav-link">
                      Sarana & Prasarana
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/hymne" class="nav-link">
                      Mars & Hymne
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                  Kesiswaan
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/siswa" class="nav-link">
                      Data Siswa
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/prestasi" class="nav-link">
                      Prestasi Siswa
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ekstra" class="nav-link">
                      Ekstrakurikuler
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/osis" class="nav-link">
                      OSIS
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/beasiswa" class="nav-link">
                      Bea Siswa
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/tatib" class="nav-link">
                      Tata Tertib
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                  Alumni
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/alumni" class="nav-link">
                      Data Alumni
                    </router-link>
                  </li>
                  
                </ul>
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                  Informasi
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/informasi" class="nav-link">
                      Jadwal Pelajaran
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/agenda" class="nav-link">
                      Agenda
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/pengumuman" class="nav-link">
                      Pengumuman
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/galeri-foto" class="nav-link">
                      Galeri Foto
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/galeri-video" class="nav-link">
                      Galeri Video
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                  PPDB
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/ppdb" class="nav-link">
                      Informasi PPDB
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/pendaftaran" class="nav-link">
                      Pendaftaran PPDB
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/seleksi" class="nav-link">
                      Pengumuman Hasil Seleksi
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/buku-tamu" class="nav-link">
                  Buku Tamu
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/login" class="nav-link">
                  Login
                </router-link>
              </li>


              <!-- <li class="nav-item">
                <router-link to="/" class="nav-link dropdown-toggle">
                  Home
                </router-link>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">
                      Home One
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/home-two" class="nav-link">
                      Home Two
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/home-three" class="nav-link">
                      Home Three
                    </router-link>
                  </li>
                </ul>
              </li> -->
              <!-- <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                  Pages
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/about" class="nav-link">
                      About 1
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/about-two" class="nav-link">
                      About 2
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/instructor" class="nav-link">
                      Instructor 1
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/instructor-two" class="nav-link">
                      Instructor 2
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/instructor-details" class="nav-link">
                      Instructor Single
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/event" class="nav-link">
                      Event
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/event-details" class="nav-link">
                      Event Single
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                  Courses
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/courses" class="nav-link">
                      Courses
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/courses-sidebar" class="nav-link">
                      Courses Sidebar
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/courses-details" class="nav-link">
                      Courses Details
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                  Blog
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/blog" class="nav-link">
                      Blog
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/blog-sidebar" class="nav-link">
                      Blog Sidebar
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/blog-standard" class="nav-link">
                      Blog Standard
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/blog-details" class="nav-link">
                      Blog Details
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/contact" class="nav-link">
                  Contact
                </router-link>
              </li> -->
            </ul>
          </div>
          <!-- <div class="nav-btn">
            <router-link to="/contact" class="default-btn">
              Start Free Trial
            </router-link>
          </div> -->
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainNavbar",
  data() {
    return {
      isSticky: false,
      active: false,
      button_active_state: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      const scrollPos = window.scrollY;
      this.isSticky = scrollPos >= 100;
    });
  },
});
</script>