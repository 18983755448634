<template>
  <div class="edu-top-navbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="top-nav-left">
            <ul>
              <li>12893 Green Valley Street, USA 83682</li>
              <li><a href="#">contactinfo@gmail.com</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="top-nav-right">
            <ul>
              <li>Follow Us On:</li>
              <li>
                <a href="https://www.facebook.com/">
                  <i class="bx bxl-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://web.skype.com/">
                  <i class="bx bxl-skype"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/">
                  <i class="bx bxl-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/">
                  <i class="bx bxl-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
};
</script>