<template>
  <div class="edu-404-area Bg404 ptb-100">
    <div class="container">
      <div class="content-404">
        <img src="../assets/img/all-img/404.svg" alt="image" />
        <h2>Page not found</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor labore et dolore magna aliqua.
        </p>
        <router-link to="/" class="default-btn"> Back To Home </router-link>
      </div>
    </div>
  </div>
</template>