<template>
  <div class="edu-courses-area pt-70 pb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="blog-details-desc">
            <div class="article-image">
              <img src="../../assets/img/all-img/b-s-1.png" alt="image" />
            </div>
            <div class="article-content">
              <div class="entry-meta">
                <ul>
                  <li><i class="bx bx-user"></i> <a href="#">Admin</a></li>
                  <li><i class="bx bx-calendar"></i>21 Feb, 23</li>
                  <li><i class="bx bx-time-five"></i> 3 Min Read</li>
                  <li>
                    <i class="bx bx-message-rounded"></i
                    ><a href="#">4 Comments</a>
                  </li>
                </ul>
              </div>

              <h2>
                Learn At Your Own Pace, with Lifetime Access on Mobile & Desktop
              </h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don’t look even
                slightly believable. As students across the globe continue to
                see their learning plans significantly.
              </p>

              <blockquote class="wp-block-quote">
                <i class="bx bxs-quote-right bx-flip-horizontal"></i>
                <p>
                  “ Education is one of the most powerful aspects of life.
                  Education and learning allow us to make sense of the world
                  around us, the world inside of us, and where we fit within the
                  world.”
                </p>
                <span>Rosalina D. Jackson</span>
              </blockquote>

              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don’t look even
                slightly believable. As students across the globe continue to
                see their learning plans significantly.
              </p>

              <ul class="wp-block-gallery columns-2">
                <li class="blocks-gallery-item">
                  <figure>
                    <img src="../../assets/img/all-img/b-s-2.png" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="../../assets/img/all-img/b-s-3.png" alt="image" />
                  </figure>
                </li>
              </ul>

              <h3>
                Education Is About Academic Excellence And Cultural Diversity
                Learning Community
              </h3>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don’t look even
                slightly believable.
              </p>

              <ul class="features-list">
                <li>Scientific skills for getting a better result</li>
                <li>Communication skills to getting in touch</li>
                <li>A career overview opportunity available</li>
              </ul>

              <div class="article-footer">
                <div class="article-tags">
                  <span>Tags:</span>
                  <a href="#">Business</a>
                  <a href="#">Education</a>
                  <a href="#">Design</a>
                </div>

                <div class="article-share">
                  <span>Share On:</span>
                  <ul class="social">
                    <li>
                      <a href="#" class="facebook" target="_blank"
                        ><img src="../../assets/img/icon/fb.svg" alt="icon"
                      /></a>
                    </li>
                    <li>
                      <a href="#" class="twitter" target="_blank"
                        ><img src="../../assets/img/icon/ins.svg" alt="icon"
                      /></a>
                    </li>
                    <li>
                      <a href="#" class="linkedin" target="_blank"
                        ><img src="../../assets/img/icon/tw.svg" alt="icon"
                      /></a>
                    </li>
                    <li>
                      <a href="#" class="linkedin" target="_blank"
                        ><img src="../../assets/img/icon/pn.svg" alt="icon"
                      /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="edu-post-navigation">
                <div class="prev-link-wrapper">
                  <div class="info-prev-link-wrapper">
                    <a href="blog-single.html">
                      <span class="image-prev">
                        <img
                          src="../../assets/img/all-img/rc-1.png"
                          alt="image"
                        />
                      </span>
                      <span class="prev-link-info-wrapper">
                        <span class="post-nav-title">Prev Post</span>
                        <span class="prev-title"
                          >How Technology Can Help You Stay Healthy</span
                        >
                      </span>
                    </a>
                  </div>
                </div>

                <div class="next-link-wrapper">
                  <div class="info-next-link-wrapper">
                    <a href="blog-single.html">
                      <span class="prev-link-info-wrapper">
                        <span class="post-nav-title">Next Post</span>
                        <span class="prev-title"
                          >How Technology Can Help You Stay Healthy</span
                        >
                      </span>
                      <span class="image-prev">
                        <img
                          src="../../assets/img/all-img/rc-2.png"
                          alt="image"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="comments-area">
              <h3 class="comments-title">2 Comments:</h3>

              <ol class="comment-list">
                <li class="comment">
                  <article class="comment-body">
                    <footer class="comment-meta">
                      <div class="comment-author vcard">
                        <img
                          src="../../assets/img/all-img/cmnt-1.png"
                          class="avatar"
                          alt="image"
                        />
                        <h4 class="fn">Ferira Watson</h4>

                        <div class="reply">
                          <a href="#" class="comment-reply-link"
                            ><i class="bx bx-share bx-flip-vertical"></i>
                            Reply</a
                          >
                        </div>
                      </div>
                      <div class="comment-metadata">
                        <span>Oct 09, 2021</span>
                      </div>
                    </footer>
                    <div class="comment-content">
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form
                      </p>
                      <div class="reply">
                        <a href="#" class="comment-reply-link"
                          ><i class="bx bx-share bx-flip-vertical"></i> Reply</a
                        >
                      </div>
                    </div>
                  </article>

                  <ol class="children">
                    <li class="comment">
                      <article class="comment-body">
                        <footer class="comment-meta">
                          <div class="comment-author vcard">
                            <img
                              src="../../assets/img/all-img/cmnt-2.png"
                              class="avatar"
                              alt="image"
                            />
                            <h4 class="fn">Steven Smith</h4>
                            <div class="reply">
                              <a href="#" class="comment-reply-link"
                                ><i class="bx bx-share bx-flip-vertical"></i>
                                Reply</a
                              >
                            </div>
                          </div>

                          <div class="comment-metadata">
                            <span>Oct 09, 2021</span>
                          </div>
                        </footer>

                        <div class="comment-content">
                          <p>
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form
                          </p>
                          <div class="reply">
                            <a href="#" class="comment-reply-link"
                              ><i class="bx bx-share bx-flip-vertical"></i>
                              Reply</a
                            >
                          </div>
                        </div>
                      </article>

                      <ol class="children">
                        <li class="comment">
                          <article class="comment-body">
                            <footer class="comment-meta">
                              <div class="comment-author vcard">
                                <img
                                  src="../../assets/img/all-img/cmnt-1.png"
                                  class="avatar"
                                  alt="image"
                                />
                                <h4 class="fn">Sarah Taylor</h4>
                                <div class="reply">
                                  <a href="#" class="comment-reply-link"
                                    ><i
                                      class="bx bx-share bx-flip-vertical"
                                    ></i>
                                    Reply</a
                                  >
                                </div>
                              </div>

                              <div class="comment-metadata">
                                <span>Oct 09, 2021</span>
                              </div>
                            </footer>

                            <div class="comment-content">
                              <p>
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form
                              </p>
                              <div class="reply">
                                <a href="#" class="comment-reply-link"
                                  ><i class="bx bx-share bx-flip-vertical"></i>
                                  Reply</a
                                >
                              </div>
                            </div>
                          </article>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <div class="comment-respond">
                <h3 class="comment-reply-title">Leave a Reply</h3>

                <form class="comment-form">
                  <p class="comment-notes">
                    <span id="email-notes"
                      >Your email address will not be published.</span
                    >
                    Required fields are marked <span class="required">*</span>
                  </p>

                  <p class="comment-form-author">
                    <label>Name <span class="required">*</span></label>
                    <input
                      type="text"
                      id="author"
                      placeholder="Your Name*"
                      name="author"
                    />
                  </p>

                  <p class="comment-form-email">
                    <label>Email <span class="required">*</span></label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Your Email*"
                      name="email"
                    />
                  </p>

                  <p class="comment-form-url">
                    <label>Website</label>
                    <input
                      type="url"
                      id="url"
                      placeholder="Website"
                      name="url"
                    />
                  </p>

                  <p class="comment-form-comment">
                    <label>Comment</label>
                    <textarea
                      name="comment"
                      id="comment"
                      cols="45"
                      placeholder="Your Comment..."
                      rows="5"
                      maxlength="65525"
                    ></textarea>
                  </p>

                  <p class="comment-form-cookies-consent">
                    <input
                      type="checkbox"
                      value="yes"
                      name="wp-comment-cookies-consent"
                      id="wp-comment-cookies-consent"
                    />
                    <label for="wp-comment-cookies-consent">
                      Save my name, email, and website in this browser for the
                      next time I comment.
                    </label>
                  </p>

                  <p class="form-submit">
                    <input
                      type="submit"
                      name="submit"
                      id="submit"
                      class="submit"
                      value="Post Comment"
                    />
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <SideBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideBar from "./SideBar.vue";

export default defineComponent({
  name: "ProfilSejarah",
  components: {
    SideBar,
  },
});
</script>