import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_TrustedByBrands = _resolveComponent("TrustedByBrands")!
  const _component_CoreFeatures = _resolveComponent("CoreFeatures")!
  const _component_PopularCourses = _resolveComponent("PopularCourses")!
  const _component_BestOnlineLearningPlatform = _resolveComponent("BestOnlineLearningPlatform")!
  const _component_SubscribeNewsletter = _resolveComponent("SubscribeNewsletter")!
  const _component_PopularTopics = _resolveComponent("PopularTopics")!
  const _component_FunFacts = _resolveComponent("FunFacts")!
  const _component_TestimonialContent = _resolveComponent("TestimonialContent")!
  const _component_TeamMembers = _resolveComponent("TeamMembers")!
  const _component_OurBlogs = _resolveComponent("OurBlogs")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainNavbar),
    _createVNode(_component_MainBanner),
    _createVNode(_component_AboutUs),
    _createVNode(_component_TrustedByBrands),
    _createVNode(_component_CoreFeatures),
    _createVNode(_component_PopularCourses),
    _createVNode(_component_BestOnlineLearningPlatform),
    _createVNode(_component_SubscribeNewsletter),
    _createVNode(_component_PopularTopics),
    _createVNode(_component_FunFacts),
    _createVNode(_component_TestimonialContent),
    _createVNode(_component_TeamMembers),
    _createVNode(_component_OurBlogs),
    _createVNode(_component_MainFooter)
  ], 64))
}