<template>
  <div class="edu-banner-area banner02">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="edu-banner-content edu-content-2">
            <h1>Better <span>Learning Future</span> Starts With EduSMART</h1>
            <p>
              It is long established fact that reader distracted by the readable
              content.
            </p>

            <div class="edu-banner-btns">
              <a class="default-btn" href="#">Get Started Now</a>
              <a class="default-btn primery-black" href="#">Get Started Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="edu-banner-image2">
            <img src="../../assets/img/banner/man2.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>