<template>
  <div class="edu-courses-area ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Popular Courses</p>
        <h2>Choose Our Top <span class="shape02">Courses</span></h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-courses-box">
            <div class="image">
              <router-link to="/courses-details" class="d-block">
                <img src="../../assets/img/all-img/c1.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Development</span></a>
              </div>
            </div>
            <div class="content">
              <span class="cr-price">$29.07</span>
              <h3>
                <router-link to="/courses-details">
                  Financial Security Thinking and Principles Theory
                </router-link>
              </h3>
              <ul class="cr-items">
                <li><i class="bx bx-food-menu"></i> <span>2 Lessons</span></li>
                <li><i class="bx bx-time-five"></i> <span>4h 30m</span></li>
                <li><i class="bx bx-star"></i> <span>4.5</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-courses-box">
            <div class="image">
              <router-link to="/courses-details" class="d-block">
                <img src="../../assets/img/all-img/c2.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Art & Design </span></a>
              </div>
            </div>
            <div class="content">
              <span class="cr-price">$80</span>
              <h3>
                <router-link to="/courses-details">
                  Basic Fundamentals of Interior & Graphics Design
                </router-link>
              </h3>
              <ul class="cr-items">
                <li><i class="bx bx-food-menu"></i> <span>2 Lessons</span></li>
                <li><i class="bx bx-time-five"></i> <span>4h 30m</span></li>
                <li><i class="bx bx-star"></i> <span>4.5</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-courses-box">
            <div class="image">
              <router-link to="/courses-details" class="d-block">
                <img src="../../assets/img/all-img/c3.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Art & Design </span></a>
              </div>
            </div>
            <div class="content">
              <span class="cr-price">$25.20</span>
              <h3>
                <router-link to="/courses-details">
                  Increasing Engagement with Instagram & Facebook
                </router-link>
              </h3>
              <ul class="cr-items">
                <li><i class="bx bx-food-menu"></i> <span>2 Lessons</span></li>
                <li><i class="bx bx-time-five"></i> <span>4h 30m</span></li>
                <li><i class="bx bx-star"></i> <span>4.5</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-courses-box">
            <div class="image">
              <router-link to="/courses-details" class="d-block">
                <img src="../../assets/img/all-img/c4.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Drawing</span></a>
              </div>
            </div>
            <div class="content">
              <span class="cr-price">$75</span>
              <h3>
                <router-link to="/courses-details">
                  Introduction to Color Theory & Basic UI/UX
                </router-link>
              </h3>
              <ul class="cr-items">
                <li><i class="bx bx-food-menu"></i> <span>2 Lessons</span></li>
                <li><i class="bx bx-time-five"></i> <span>4h 30m</span></li>
                <li><i class="bx bx-star"></i> <span>4.5</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-courses-box">
            <div class="image">
              <router-link to="/courses-details" class="d-block">
                <img src="../../assets/img/all-img/c5.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Technology</span></a>
              </div>
            </div>
            <div class="content">
              <span class="cr-price">$36.20</span>
              <h3>
                <router-link to="/courses-details">
                  Financial Security Thinking and Principles Theory
                </router-link>
              </h3>
              <ul class="cr-items">
                <li><i class="bx bx-food-menu"></i> <span>2 Lessons</span></li>
                <li><i class="bx bx-time-five"></i> <span>4h 30m</span></li>
                <li><i class="bx bx-star"></i> <span>4.5</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-courses-box">
            <div class="image">
              <router-link to="/courses-details" class="d-block">
                <img src="../../assets/img/all-img/c6.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Data Science</span></a>
              </div>
            </div>
            <div class="content">
              <span class="cr-price">$38</span>
              <h3>
                <router-link to="/courses-details">
                  Free Logo Design: From Concept to Presentation
                </router-link>
              </h3>
              <ul class="cr-items">
                <li><i class="bx bx-food-menu"></i> <span>2 Lessons</span></li>
                <li><i class="bx bx-time-five"></i> <span>4h 30m</span></li>
                <li><i class="bx bx-star"></i> <span>4.5</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section-button">
          <router-link to="/courses" class="default-btn">
            View All Courses
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopularCourses",
};
</script>