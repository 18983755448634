<template>
  <div class="edu-team-area ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Team Member</p>
        <h2>Our Expert <span class="shape02">Instructors</span></h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="single-team-box02">
            <div class="image">
              <img src="../../assets/img/all-img/team5.png" alt="image" />
              <div class="social-links">
                <div class="hover-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" class="sg-1">
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" class="sg-2">
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/" class="sg-3">
                        <i class="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                  <i class="bx bx-plus"></i>
                </div>
              </div>
            </div>
            <div class="content">
              <h3>Erics Widget</h3>
              <p>UI/UX Designer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="single-team-box02">
            <div class="image">
              <img src="../../assets/img/all-img/team6.png" alt="image" />
              <div class="social-links">
                <div class="hover-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" class="sg-1">
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" class="sg-2">
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/" class="sg-3">
                        <i class="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                  <i class="bx bx-plus"></i>
                </div>
              </div>
            </div>
            <div class="content">
              <h3>Daniel Steven</h3>
              <p>UI/UX Designer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="single-team-box02">
            <div class="image">
              <img src="../../assets/img/all-img/team7.png" alt="image" />
              <div class="social-links">
                <div class="hover-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" class="sg-1">
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" class="sg-2">
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/" class="sg-3">
                        <i class="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                  <i class="bx bx-plus"></i>
                </div>
              </div>
            </div>
            <div class="content">
              <h3>Nelson Decosta</h3>
              <p>UI/UX Designer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="single-team-box02">
            <div class="image">
              <img src="../../assets/img/all-img/team8.png" alt="image" />
              <div class="social-links">
                <div class="hover-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" class="sg-1">
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" class="sg-2">
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/" class="sg-3">
                        <i class="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                  <i class="bx bx-plus"></i>
                </div>
              </div>
            </div>
            <div class="content">
              <h3>Selina Gomez</h3>
              <p>UI/UX Designer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamMember",
};
</script>