import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_CourseCategories = _resolveComponent("CourseCategories")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_FeaturedCourses = _resolveComponent("FeaturedCourses")!
  const _component_FunFacts = _resolveComponent("FunFacts")!
  const _component_VideoContent = _resolveComponent("VideoContent")!
  const _component_WhyChooseUs = _resolveComponent("WhyChooseUs")!
  const _component_OurGreatAchievement = _resolveComponent("OurGreatAchievement")!
  const _component_OurBlogs = _resolveComponent("OurBlogs")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopBar),
    _createVNode(_component_MainNavbar, { class: "edu-navbar-area2" }),
    _createVNode(_component_MainBanner),
    _createVNode(_component_CourseCategories),
    _createVNode(_component_AboutUs),
    _createVNode(_component_FeaturedCourses),
    _createVNode(_component_FunFacts),
    _createVNode(_component_VideoContent),
    _createVNode(_component_WhyChooseUs),
    _createVNode(_component_OurGreatAchievement),
    _createVNode(_component_OurBlogs),
    _createVNode(_component_MainFooter)
  ], 64))
}