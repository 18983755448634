<template>
  <div class="edu-testimonial-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="edu-review">
            <div class="image">
              <img src="../../assets/img/all-img/ins-2.png" alt="icon" />
            </div>
            <div class="content">
              <p>
                You might be raised on a farm. Sometimes languages are difficult
                and difficult to find the right the adjective to go with noun.
              </p>
              <ul class="ratings">
                <li><i class="bx bxs-star"></i></li>
                <li><i class="bx bxs-star"></i></li>
                <li><i class="bx bxs-star"></i></li>
                <li><i class="bx bxs-star"></i></li>
                <li><i class="bx bxs-star"></i></li>
              </ul>
              <h3>Jhon Smith</h3>
              <span>UI UX Designer</span>
            </div>
          </div>
          <div class="edu-review-box">
            <p>
              "You own your data and gain complete control over your business."
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="edu-set-box">
            <h3>4.9/5</h3>
            <ul class="ratings">
              <li><i class="bx bxs-star"></i></li>
              <li><i class="bx bxs-star"></i></li>
              <li><i class="bx bxs-star"></i></li>
              <li><i class="bx bxs-star"></i></li>
              <li><i class="bx bxs-star"></i></li>
            </ul>
            <p>2000+ Satisfied Students</p>
          </div>
          <div class="edu-review-single">
            <div class="image">
              <img src="../../assets/img/all-img/ins-3.png" alt="icon" />
            </div>
            <div class="content">
              <p>
                You might be raised on a farm. Sometimes some languages and
                difficult for difficult to find.
              </p>
              <ul class="ratings">
                <li><i class="bx bxs-star"></i></li>
                <li><i class="bx bxs-star"></i></li>
                <li><i class="bx bxs-star"></i></li>
                <li><i class="bx bxs-star"></i></li>
                <li><i class="bx bxs-star"></i></li>
              </ul>
              <h3>Jhon Smith</h3>
              <span>UI UX Designer</span>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="edu-testimonial-content">
            <p class="sub-title">Testimonial</p>
            <h2>
              What Says Our <span class="shape02">Students</span> About Us
            </h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered. There are many variations of
              passages of Lorem Ipsum available, but the majority have suffered
              alteration in some form, by injected humour.
            </p>
            <a href="#" class="default-btn">View All Reviews</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialContent",
};
</script>