<template>
  <MainNavbar class="edu-fixed-nav" />
  <PageTitle pageTitle="Kata Pengantar" mainTitle="Kata Pengantar" />
  <ProfilPengantar />
  <MainFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainNavbar from "../components/Layouts/MainNavbar.vue";
import PageTitle from "../components/Common/PageTitle.vue";
import ProfilPengantar from "../components/ProfilPengantar/ProfilPengantar.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "ProfilPengantarPage",
  components: {
    MainNavbar,
    PageTitle,
    ProfilPengantar,
    MainFooter,
  },
});
</script>