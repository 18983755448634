<template>
  <div class="edu-whyChoose-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-12 col-sm-12">
          <div class="edu-whyChoose-content">
            <p class="sub-title">Why Choose Edumim</p>
            <h2>
              Grow Your Skill, Define Your
              <span class="shape02">Bright Future </span>
            </h2>
            <p>
              Learn at your own pace, move between multiple courses, or switch
              to a different course. Earn a certificate for every learning
              program that you complete at no additional cost multiple courses
              this year.
            </p>
            <div class="whyChoose-list">
              <div class="items">
                <div class="edu-whyChoose-img">
                  <img src="../../assets/img/svg/mini-target.svg" alt="icon" />
                </div>
                <div class="whyChoose-single-content">
                  <h3>Learn At Your Own Multiple Courses</h3>
                </div>
              </div>
              <div class="items">
                <div class="edu-whyChoose-img">
                  <img src="../../assets/img/svg/mini-heart.svg" alt="icon" />
                </div>
                <div class="whyChoose-single-content">
                  <h3>We Provide Study Materials</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12">
          <div class="edu-whyChoose-image">
            <img src="../../assets/img/all-img/about4.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUs",
};
</script>