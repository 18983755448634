import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_TeamMembers = _resolveComponent("TeamMembers")!
  const _component_TestimonialContent = _resolveComponent("TestimonialContent")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainNavbar, { class: "edu-fixed-nav" }),
    _createVNode(_component_PageTitle, {
      pageTitle: "Instructor",
      mainTitle: "Instructor 1"
    }),
    _createVNode(_component_TeamMembers),
    _createVNode(_component_TestimonialContent),
    _createVNode(_component_MainFooter)
  ], 64))
}