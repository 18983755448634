<template>
  <div class="edu-faq-area sectionBg15 ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="edu-faq-content">
            <p class="sub-title">Frequently Asked Question</p>
            <h2>General <span class="shape02">Questions</span></h2>
          </div>
          <div class="accordion edu-faqs-list" id="accordionFlushExample">
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingOne">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What does it take excellent author?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    Learn at your own pace, move between multiple courses, or
                    switch to a different course. Earn a certificate for every
                    learning program that you complete at no additional cost.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingTow">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTow"
                  aria-expanded="false"
                  aria-controls="flush-collapseTow"
                >
                  Who will view my content?
                </button>
              </h2>
              <div
                id="flush-collapseTow"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingTow"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    Learn at your own pace, move between multiple courses, or
                    switch to a different course. Earn a certificate for every
                    learning program that you complete at no additional cost.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingThree">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What does it take become an author?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    Learn at your own pace, move between multiple courses, or
                    switch to a different course. Earn a certificate for every
                    learning program that you complete at no additional cost.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingFour">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  How to Change my Password easily?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    Learn at your own pace, move between multiple courses, or
                    switch to a different course. Earn a certificate for every
                    learning program that you complete at no additional cost.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="edu-about-image">
            <img src="../../assets/img/all-img/faq.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
};
</script>