<template>
  <div class="brands-area sectionBg07 ptb-100">
    <div class="container">
      <div class="brands-title">
        <h3><span class="mini-shape">Trusted</span> By 1000+ Companies</h3>
      </div>
      <ul class="brands-list">
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/1.svg" alt="brands" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/2.svg" alt="brands" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/3.svg" alt="brands" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/4.svg" alt="brands" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src="../../assets/img/all-img/brands/5.svg" alt="brands" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrustedByBrands",
};
</script>