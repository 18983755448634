<template>
  <div class="edu-blog-area ptb-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-blog-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/blog-1.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Development</span></a>
              </div>
            </div>
            <div class="content">
              <ul class="cr-items">
                <li>
                  <a href="#"><i class="bx bx-user"></i><span>Admin</span></a>
                </li>
                <li><i class="bx bx-star"></i><span>Jan 29, 2023</span></li>
              </ul>
              <h3>
                <router-link to="/blog-details"
                  >Financial Security Thinking and Principles
                  Theory</router-link
                >
              </h3>
              <router-link class="blog-btn" to="/blog-details"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-blog-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/blog-2.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Education</span></a>
              </div>
            </div>
            <div class="content">
              <ul class="cr-items">
                <li>
                  <a href="#"><i class="bx bx-user"></i><span>Admin</span></a>
                </li>
                <li><i class="bx bx-star"></i><span>Jan 29, 2023</span></li>
              </ul>
              <h3>
                <router-link to="/blog-details">
                  Professional Ceramic Moulding for Beginners
                </router-link>
              </h3>
              <router-link class="blog-btn" to="/blog-details"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-blog-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/blog-3.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Education</span></a>
              </div>
            </div>
            <div class="content">
              <ul class="cr-items">
                <li>
                  <a href="#"><i class="bx bx-user"></i><span>Admin</span></a>
                </li>
                <li><i class="bx bx-star"></i><span>Jan 29, 2023</span></li>
              </ul>
              <h3>
                <router-link to="/blog-details">
                  Education Is About Create Leaders For Tomorrow
                </router-link>
              </h3>
              <router-link class="blog-btn" to="/blog-details"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-blog-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/blog-4.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Education</span></a>
              </div>
            </div>
            <div class="content">
              <ul class="cr-items">
                <li>
                  <a href="#"><i class="bx bx-user"></i><span>Admin</span></a>
                </li>
                <li><i class="bx bx-star"></i><span>Jan 29, 2023</span></li>
              </ul>
              <h3>
                <router-link to="/blog-details">
                  Education Is About Create Leaders For Tomorrow
                </router-link>
              </h3>
              <router-link class="blog-btn" to="/blog-details"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-blog-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/blog-5.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Education</span></a>
              </div>
            </div>
            <div class="content">
              <ul class="cr-items">
                <li>
                  <a href="#"><i class="bx bx-user"></i><span>Admin</span></a>
                </li>
                <li><i class="bx bx-star"></i><span>Jan 29, 2023</span></li>
              </ul>
              <h3>
                <router-link to="/blog-details">
                  Professional Ceramic Moulding for Beginners
                </router-link>
              </h3>
              <router-link class="blog-btn" to="/blog-details"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-blog-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/blog-6.png" alt="image" />
              </router-link>
              <div class="cr-tag">
                <a href="#"><span>Education</span></a>
              </div>
            </div>
            <div class="content">
              <ul class="cr-items">
                <li>
                  <a href="#"><i class="bx bx-user"></i><span>Admin</span></a>
                </li>
                <li><i class="bx bx-star"></i><span>Jan 29, 2023</span></li>
              </ul>
              <h3>
                <router-link to="/blog-details">
                  Professional Mobile Painting and Sculpting
                </router-link>
              </h3>
              <router-link class="blog-btn" to="/blog-details"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="pagination-area text-center">
            <a href="#" class="prev page-numbers">
              <i class="bx bx-chevrons-left"></i>
            </a>
            <span class="page-numbers current" aria-current="page">1</span>
            <a href="#" class="page-numbers">2</a>
            <a href="#" class="page-numbers">3</a>
            <a href="#" class="page-numbers">4</a>
            <a href="#" class="next page-numbers">
              <i class="bx bx-chevrons-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogGrid",
};
</script>