<template>
  <div class="edu-artical-area ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Blog & Airticle</p>
        <h2>Take A Look At The Latest <span class="shape02">Articles</span></h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="single-artical-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/c1.png" alt="image" />
              </router-link>
            </div>
            <div class="content">
              <div class="cr-tag">
                <a href="#"><span>Learning</span></a>
              </div>
              <h3>
                <router-link to="/blog-details">
                  Fashion and Luxury Fashion in a Changing
                </router-link>
              </h3>
              <ul class="cr-items">
                <li>
                  <a href="#">
                    <i class="bx bx-calendar"></i>
                    <span>01 Feb, 24</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="bx bx-user"></i>
                    <span>4k Students</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="single-artical-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/c2.png" alt="image" />
              </router-link>
            </div>
            <div class="content">
              <div class="cr-tag">
                <a href="#"><span>Learning</span></a>
              </div>
              <h3>
                <router-link to="/blog-details">
                  Creative Writing Through Storytelling
                </router-link>
              </h3>
              <ul class="cr-items">
                <li>
                  <a href="#">
                    <i class="bx bx-calendar"></i>
                    <span>19 Feb, 24</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="bx bx-user"></i>
                    <span>5k Students</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="single-artical-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/c3.png" alt="image" />
              </router-link>
            </div>
            <div class="content">
              <div class="cr-tag">
                <a href="#"><span>Learning</span></a>
              </div>
              <h3>
                <router-link to="/blog-details">
                  Product Manager Learn The Skills & Job
                </router-link>
              </h3>
              <ul class="cr-items">
                <li>
                  <a href="#">
                    <i class="bx bx-calendar"></i>
                    <span>21 Feb, 24</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="bx bx-user"></i>
                    <span>2k Students</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="single-artical-box">
            <div class="image">
              <router-link to="/blog-details" class="d-block">
                <img src="../../assets/img/all-img/c4.png" alt="image" />
              </router-link>
            </div>
            <div class="content">
              <div class="cr-tag">
                <a href="#"><span>Learning</span></a>
              </div>
              <h3>
                <router-link to="/blog-details">
                  The Power of Podcast for Storytelling
                </router-link>
              </h3>
              <ul class="cr-items">
                <li>
                  <a href="#">
                    <i class="bx bx-calendar"></i>
                    <span>12 Feb, 24</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="bx bx-user"></i>
                    <span>1k Students</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurBlogs",
};
</script>