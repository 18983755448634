<template>
  <div class="edu-priceing-area sectionBg09 ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Some Fun Fact</p>
        <h2>Our Great <span class="shape02">Achievement</span></h2>
      </div>
      <div class="pr-line"></div>
      <div class="priceing-table price-regular">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="priceing-header">
              <h3>Starter</h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="priceing-features-list">
              <ul>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Sed sed felis neque vehicula iaculis</p>
                </li>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Cras consequat ligula vel augue</p>
                </li>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Maecenas bibendum massa eget</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-12 col-sm-12">
            <div class="prie">
              <h2>Free</h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="get-btn">
              <router-link to="/contact" class="default-btn primery-black">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="priceing-table price-professional">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="priceing-header">
              <h3>Professional</h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="priceing-features-list">
              <ul>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Sed sed felis neque vehicula iaculis</p>
                </li>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Cras consequat ligula vel augue</p>
                </li>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Maecenas bibendum massa eget</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-12 col-sm-12">
            <div class="prie">
              <h2>$89.69</h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="get-btn">
              <router-link to="/contact" class="default-btn">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="priceing-table price-regular">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="priceing-header">
              <h3>Business</h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="priceing-features-list">
              <ul>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Sed sed felis neque vehicula iaculis</p>
                </li>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Cras consequat ligula vel augue</p>
                </li>
                <li>
                  <img src="../../assets/img/svg/check.svg" alt="icon" />
                  <p>Maecenas bibendum massa eget</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-12 col-sm-12">
            <div class="prie">
              <h2>$129.69</h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="get-btn">
              <router-link to="/contact" class="default-btn primery-black">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurGreatAchievement",
};
</script>