<template>
  <div class="edu-counter-area">
    <div class="container">
      <div class="counter-box">
        <ul class="counter-list">
          <li>
            <h3>
              82
              <span class="sign">K+</span>
            </h3>
            <p>Enrolled Students</p>
          </li>
          <li>
            <h3>
              348
              <span class="sign">+</span>
            </h3>
            <p>Academic Programs</p>
          </li>
          <li>
            <h3>
              125
              <span class="sign">+</span>
            </h3>
            <p>Winning Award</p>
          </li>
          <li>
            <h3>
              37
              <span class="sign">+</span>
            </h3>
            <p>Certified Students</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FunFacts",
};
</script>